import React from 'react'
import ReactHelmet from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { ReactFCWithChildren } from '@/react'

interface MetaTagsProviderProps {
  title: string
  metaDescription: string
}

const MetaTagsProvider: ReactFCWithChildren<MetaTagsProviderProps> = ({
  title,
  metaDescription,
  children,
}) => {
  const { i18n } = useTranslation()

  return (
    <>
      <ReactHelmet htmlAttributes={{ lang: i18n.language }}>
        <title>{title}</title>
        <meta name='description' content={metaDescription} />
      </ReactHelmet>
      {children}
    </>
  )
}

export default MetaTagsProvider
