import React, { FC } from 'react'
import ReactHelmet from 'react-helmet'
import { useLocation } from 'react-router-dom'

import FaviconApple from '@/assets/favicon/apple-touch-icon.png'
import Favicon from '@/assets/favicon/favicon.ico'
import Favicon16 from '@/assets/favicon/favicon-16x16.png'
import Favicon32 from '@/assets/favicon/favicon-32x32.png'
import FaviconSafari from '@/assets/favicon/safari-pinned-tab.svg'
import Noob from '@/components/Noob'

const PRODUCTION_ENV = import.meta.env.VITE_APP_ENV === 'production'
const ADD_GTM_SCRIPT = /^true$/i.test(import.meta.env.VITE_APP_ADD_GOOGLE_TAG_MANAGER_SCRIPT)

const withHelmet =
  <T extends object>(WrappedComponent: React.ComponentType<T>): FC<T> =>
  (props) => {
    const location = useLocation()

    const isRegisterRoute =
      location.pathname === '/registration/company' || location.pathname === '/registration/student'

    const renderHsScript =
      PRODUCTION_ENV && isRegisterRoute ? (
        <script
          type='text/javascript'
          id='hs-script-loader'
          async
          defer
          src='//js-eu1.hs-scripts.com/26104416.js'
        />
      ) : (
        <Noob />
      )
    const renderGoogleTagManagerHeadScript = ADD_GTM_SCRIPT ? (
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TB4QLF4');`}</script>
    ) : (
      <Noob />
    )

    return (
      <>
        <ReactHelmet
          link={[
            {
              rel: 'icon',
              type: 'image/png',
              href: Favicon,
            },
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '16x16',
              href: Favicon16,
            },
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '32x32',
              href: Favicon32,
            },
            {
              rel: 'mask-icon',
              href: FaviconSafari,
            },
            {
              rel: 'apple-touch-icon',
              href: FaviconApple,
            },
          ]}
        >
          {renderGoogleTagManagerHeadScript}
          {renderHsScript}
        </ReactHelmet>
        <WrappedComponent {...props} />
      </>
    )
  }

export default withHelmet
